import { createSlice } from '@reduxjs/toolkit';
import { remove, set } from 'lodash';

export const initialState = {
    currentRouteKey: '',
    isUndo: true,
    isRedo: true,
    undoRedoBtn:'',

    entityInfo:{
        id: '',
        data: {
            entityId: '',  // 서버의 엔터티 아이디
            entityName: '',
            entityDescription: '',
            physicalName: '',
        },
        entityType: '',
    },
    focusInfo:{
        focusArea: '',
        focusName: '',
        focusDescription: ''
    },
    modelInfo:{
        key             : '',  // 모델 키
        modelName       : '',
        modelDescription: '',
        anotherSaveName : '',
        openMethod      : '',  // openMethod: '신규' 추가
        isModified      : false, // 수정 여부
        isLockHold      : false,
        isNewModel      : false,
        isNewOpen       : false,
        nodes           : [],
        edges           : [],
        viewport        : {},
        attrs           : [],
        mdlrmdlId       : '',  // 서버의 모델 아이디
    },
    edgeInfo: {
        id: '',
        isIdenRel: false,
        nullCheck: false,
        markerStart:'',
        markerEnd  :'',
        source : '',
        target : '',
        sourceHandle : '',
        targetHandle : '',
        style : '',
    },
    exportData: {}, 
    importData: {}, 
    multiModelData: [],  
    itemMenu: [],
    //attrs: [],
    diagramMode: 'L', 
    relType: 'IR',  
    isDetailVisible: false,
    userLoggingMode: 'B',
    deleteEntityId: null,
    identifierChanged: {
        entityId: '', 
        itemId: '',
        type: ''
    },
    memoInfo: null,
    viewport: null,
    // dictionary 관련 상태 추가
    dictionary: {
        words: [],
        domainGroup: [], // 추가
        infotype: [], // 추가
        loading: false,
        error: null
    },
    modelConfigs: {}, // 모델별 설정을 저장할 객체
    favoriteList: [
        { shortLabel: 'N', label: '신규' },
        { shortLabel: 'O', label: '열기' },
        { shortLabel: 'S', label: '저장' },
        { shortLabel: 'E', label: '엔터티 추가' },
        { shortLabel: 'IR', label: '식별 관계선 선택' },
        { shortLabel: 'NR', label: '비식별 관계선 선택' },
      ],
    toolbarLists: [],

    liveParticipation: {
        mdlrmdlId: '', 
        shareGroupId: '', 
        assignmentName:''
    },
    liveModel: {}, // 내가 방송중인 모델
    liveMdlrmdlId: '', // 내가 방송중인 모델 아이디
    lastModels: [],  // 인트로 화면에 최근 4개 모델의 미리보기를 보여주기 위한 리스트 
}

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {

        setFavoriteList(state, action) {
            state.favoriteList = [...action.payload]; // 불변성을 유지하기 위해 새로운 배열로 할당
          },

        setLiveModel: (state, action) => {
            state.liveModel = action.payload;
        },
        setLiveMdlrmdlId: (state, action) => {
            state.liveMdlrmdlId = action.payload;
        },

        setLiveParticipation: (state, action) => {
            state.liveParticipation = action.payload;
        },

        setToolbarLists: (state, action) => {
            state.toolbarLists = action.payload;
        },

        /*
        addFavorite: (state, action) => {
            state.favoriteList = state.favoriteList.concat(action.payload);
        },
        removeFavorite: (state, action) => {
            state.favoriteList = state.favoriteList.filter(favorite => favorite.shortLabel !== action.payload);
        },
        */
        addFavorite: (state, action) => {
            if (!state.favoriteList.some(item => item.shortLabel === action.payload.shortLabel)) {
              state.favoriteList.push(action.payload);
            }
          },
          removeFavorite: (state, action) => {
            state.favoriteList = state.favoriteList.filter(
              item => item.shortLabel !== action.payload
            );
          },

        setViewport: (state, action) => {
            state.viewport = action.payload;
        },
        setMemoInfo: (state, action) => {
            state.memoInfo = action.payload;
        },
        setIdentifierChanged: (state, action) => {
            state.identifierChanged = action.payload
        },
        resetState: (state) => initialState,
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setRelType: (state, action) => {
            state.relType = action.payload
        },
        setIsUndo: (state, action) => {
            state.isUndo = action.payload
        },
        setIsRedo: (state, action) => {
            state.isRedo = action.payload
        },
        setFocusInfo: (state, action) => {
            console.log("setFocusInfo action.payload",action.payload);
            state.focusInfo = action.payload
        },
        setModelInfo: (state, action) => {
            console.log("setModelInfo action.payload",action.payload);
            state.modelInfo = action.payload
        },

        setEntityInfo: (state, action) => {
            console.log("setEntityInfo action.payload",action.payload);
            state.entityInfo = action.payload
        },
        updateEntityName: (state, action) => {
            console.log("updateEntityName action.payload",action.payload);
            state.entityInfo.data.entityName = action.payload;
        },
        updatePhysicalName: (state, action) => {
            console.log("updatePhysicalName action.payload",action.payload);
            state.entityInfo.data.physicalName = action.payload;
        },
        /*
        setPropertyInfo: (state, action) => {
            state.propertyInfo = action.payload
        },
        */
        setEdgeInfo: (state, action) => {
            state.edgeInfo = action.payload
        },
        setStoreData: (state, action) => {            
            state.storeData = action.payload
            console.log("setStoreData payload:", action.payload)
        },
        setExportData: (state, action) => {            
            state.exportData = action.payload
            console.log("setExportData payload:", action.payload)
        },
        setImportData: (state, action) => {            
            state.importData = action.payload
            console.log("setImportData payload:", action.payload)
        },
        setMultiModelData: (state, action) => {
            console.log("setMultiModelData action.payload",action.payload);
            state.multiModelData = action.payload
        },

        /*
        addMultiModelData : (state, action) => {
            console.log("addMultiModelData action.payload", action.payload);
        
            const addModel = (model) => {
                if (!model.key) {
                    console.warn("Model key is missing for model:", model);
                    return; // 모델 키가 없으면 추가하지 않고 함수 종료
                }
        
                const existingIndex = state.multiModelData.findIndex(m => m.key === model.key);
        
                if (existingIndex !== -1) {
                    // 기존 모델이 존재하는 경우 업데이트
                    state.multiModelData[existingIndex] = model;
                } else {
                    // 새로운 모델을 기존 리스트에 추가
                    state.multiModelData.push(model);
                }
            };
        
            if (Array.isArray(action.payload)) {
                // 배열로 여러 모델이 전달된 경우
                action.payload.forEach(addModel);
            } else {
                // 단일 모델 객체가 전달된 경우
                addModel(action.payload);
            }
        },
        */
        addMultiModelData: (state, action) => {
            console.log("addMultiModelData action.payload", action.payload);
        
            const addModel = (model) => {
                if (!model.mdlrmdlId && !model.key) {
                    console.warn("Both mdlrmdlId and key are missing for model:", model);
                    return; // mdlrmdlId와 key 모두 없으면 추가하지 않고 함수 종료
                }
        
                let existingIndex = -1;
        
                if (model.mdlrmdlId) {
                    // mdlrmdlId로 먼저 검색
                    existingIndex = state.multiModelData.findIndex(m => m.mdlrmdlId === model.mdlrmdlId);
                }
        
                if (existingIndex === -1 && model.key) {
                    // mdlrmdlId로 찾지 못한 경우, key로 검색
                    existingIndex = state.multiModelData.findIndex(m => m.key === model.key);
                }
        
                if (existingIndex !== -1) {
                    // 기존 모델이 존재하는 경우 업데이트
                    state.multiModelData[existingIndex] = {
                        ...state.multiModelData[existingIndex],
                        ...model
                    };
                } else {
                    // 새로운 모델을 기존 리스트에 추가
                    state.multiModelData.push(model);
                }
            };
        
            if (Array.isArray(action.payload)) {
                // 배열로 여러 모델이 전달된 경우
                action.payload.forEach(addModel);
            } else {
                // 단일 모델 객체가 전달된 경우
                addModel(action.payload);
            }
        },

        
        renameMultiModelData: (state, action) => {
            console.log("renameMultiModelData action.payload", action.payload);
            const { modelKey, newModelName } = action.payload;
        
            if (!modelKey) {
                throw new Error("Model key is missing");
            }
        
            const modelIndex = state.multiModelData.findIndex(model => model.key === modelKey);
            if (modelIndex === -1) {
                throw new Error(`Model with key ${modelKey} not found`);
            }
        
            state.multiModelData[modelIndex] = {
                ...state.multiModelData[modelIndex],
                modelName: newModelName
            };
        },
        deleteMultiModelData: (state, action) => {
            const modelKey = action.payload;
            
            if (!modelKey) {
                throw new Error("Model key is missing");
            }
        
            const initialLength = state.multiModelData.length;
            state.multiModelData = state.multiModelData.filter(model => model.key !== modelKey);
        
            if (state.multiModelData.length === initialLength) {
                throw new Error(`Model with key ${modelKey} not found`);
            }
        },
        setItemMenu: (state, action) => {
            state.itemMenu = action.payload
        },
        setDiagramMode: (state, action) => {
            state.diagramMode = action.payload
        },
        setUserLoggingMode: (state, action) => {
            state.userLoggingMode = action.payload
        },

        addAttrs: (state, action) => {
            state.modelInfo.attrs = state.modelInfo.attrs.concat(action.payload);
        },

        addItem: (state, action) => {
            state.modelInfo.attrs = state.modelInfo.attrs.map(attr =>
                attr.entityId === action.payload.targetEntityId
                    ? { ...attr, items: [...attr.items, action.payload.newItem] }
                    : attr
            );
        },
        removeAttrs: (state, action) => {
            state.modelInfo.attrs = state.modelInfo.attrs.filter(attr => attr.entityId !== action.payload);
        },
        removeAttr: (state, action) => {
            state.modelInfo.attrs = state.modelInfo.attrs.map(attr => {
                if (attr.entityId === action.payload.entityId) {
                    return {
                        ...attr,
                        items: attr.items.filter(item => item.id !== action.payload.id),
                    };
                }
                return attr;
            });
        },
        removeEdgeItem: (state, action) => {
            state.modelInfo.attrs = state.modelInfo.attrs.map(attr => {
                if (attr.entityId === action.payload.entityId) {
                    return {
                        ...attr,
                        items: attr.items.filter(item => item.edgeId !== action.payload.edgeId),
                    };
                }
                return attr;
            });
        },
        clearAttrs: (state) => {
            state.modelInfo.attrs = [];
        },
        replaceAttrs: (state, action) => {
            state.modelInfo.attrs = action.payload;
        },
        mergeItems: (state, action) => {
            const { entityId, items } = action.payload;
            state.modelInfo.attrs = state.modelInfo.attrs.map(attr => {
                if (attr.entityId === action.payload.entityId) {
                    const existingIds = new Set(attr.items.filter(item => item.id !== undefined).map(item => item.id));
                    const mergedItems = [
                        ...attr.items,
                        ...items.filter(item => item && item.id !== undefined && !existingIds.has(item.id))
                    ];
                    return {
                        ...attr,
                        items: mergedItems
                    };
                }
                return attr;
            });
        },
        replaceAttrItems: (state, action) => {
            state.modelInfo.attrs = state.modelInfo.attrs.map(attr => {
                if (attr.entityId === action.payload.entityId) {
                    return { ...attr, items: action.payload.items };
                }
                return attr;
            });
        },
        replaceMultipleAttrItems: (state, action) => {
            const updates = action.payload.updates;
            if (!Array.isArray(updates)) {
                console.error("Invalid updates payload:", updates);
                return;
            }
            updates.forEach(update => {
                state.modelInfo.attrs = state.modelInfo.attrs.map(attr => {
                    if (attr.entityId === update.entityId) {
                        return { ...attr, items: update.items };
                    }
                    return attr;
                });
            });
        },     
        deselectItems : (state, action) => {
            const { entityId } = action.payload;
        
            if (!Array.isArray(state.modelInfo.attrs)) return;
        
            state.modelInfo.attrs = state.modelInfo.attrs.map(attr => {
                if (attr.entityId !== entityId && Array.isArray(attr.items)) {
                    const updatedItems = attr.items.map(item => {
                        if (item.selected) {
                            return { ...item, selected: false };
                        }
                        return item;
                    });
                    return { ...attr, items: updatedItems };
                }
                return attr;
            });
        },

        setDeleteEntityId: (state, action) => {
            state.deleteEntityId = action.payload;
        },
        clearDeleteEntityId: (state) => {
            state.deleteEntityId = null;
        },
        toggleDetailVisibility: (state) => {
            state.isDetailVisible = !state.isDetailVisible;
        },
        setIsDetailVisible: (state, action) => {
            state.isDetailVisible =  action.payload;
        // -------------------------------
        },
        setDictionaryWordsLoading: (state) => {
            state.dictionary.loading = true;
            state.dictionary.error = null;
        },
        setDictionaryWordsSuccess: (state, action) => {
            state.dictionary.words = action.payload;
            state.dictionary.loading = false;
        },
        setDictionaryWordsFailure: (state, action) => {
            state.dictionary.loading = false;
            state.dictionary.error = action.payload;
        },
        // -------------------------------
        setDictionaryDomainGroupLoading: (state) => {
            state.dictionary.loading = true;
            state.dictionary.error = null;
        },
        setDictionaryDomainGroupSuccess: (state, action) => {
            state.dictionary.domainGroup = action.payload;
            state.dictionary.loading = false;
        },
        setDictionaryDomainGroupFailure: (state, action) => {
            state.dictionary.loading = false;
            state.dictionary.error = action.payload;
        },
        // -------------------------------
        setDictionaryInfotypeLoading: (state) => {
            state.dictionary.loading = true;
            state.dictionary.error = null;
        },
        setDictionaryInfotypeSuccess: (state, action) => {
            state.dictionary.infotype = action.payload;
            state.dictionary.loading = false;
        },
        setDictionaryInfotypeFailure: (state, action) => {
            state.dictionary.loading = false;
            state.dictionary.error = action.payload;
        },
        // -------------------------------

        updateEntityPhysicalNames: (state, action) => {
            state.modelInfo.nodes = state.modelInfo.nodes.map(node => {
              const update = action.payload.find(u => u.entityId === node.id);
              if (update) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    physicalName: update.newPhysicalName
                  },
                  entityType: '',
                };
              }
              return node;
            });
          },
        // ----------------------------
        setModelConfig: (state, action) => {
            const { modelId, config } = action.payload;
            state.modelConfigs[modelId] = config;
        },
        updateModelConfigTab: (state, action) => {
            const { modelId, tabIndex, newTabData } = action.payload;
            if (state.modelConfigs[modelId]?.tabs[tabIndex]) {
                state.modelConfigs[modelId].tabs[tabIndex] = {
                    ...state.modelConfigs[modelId].tabs[tabIndex],
                    ...newTabData
                };
            }
        },
        updateModelConfigSection: (state, action) => {
            const { modelId, tabIndex, sectionIndex, newSectionData } = action.payload;
            if (state.modelConfigs[modelId]?.tabs[tabIndex]?.sections[sectionIndex]) {
                state.modelConfigs[modelId].tabs[tabIndex].sections[sectionIndex] = {
                    ...state.modelConfigs[modelId].tabs[tabIndex].sections[sectionIndex],
                    ...newSectionData
                };
            }
        },
        removeModelConfig: (state, action) => {
            const modelId = action.payload;
            delete state.modelConfigs[modelId];
        },
        // ----------------------------

    },
})

export const { 
    resetState,
    setCurrentRouteKey, 
    setRelType, 
    setIsUndo, 
    setIsRedo, 
    setFocusInfo, 
    setModelInfo, 
    setEntityInfo, 
    setPropertyInfo, 
    setEdgeInfo, 
    setStoreData, 
    setExportData, 
    setImportData, 
    setMultiModelData,
    addMultiModelData,
    renameMultiModelData,
    deleteMultiModelData,
    setItemMenu, 
    setDiagramMode,
    addAttrs,
    removeAttrs,
    removeAttr,
    clearAttrs,
    replaceAttrs,
    mergeItems,
    replaceAttrItems,
    replaceMultipleAttrItems,
    addItem,
    removeEdgeItem,
    deselectItems,
    updateEntityName,
    updatePhysicalName,
    setDeleteEntityId, 
    clearDeleteEntityId,
    deleteEntityId,
    toggleDetailVisibility,
    setIsDetailVisible,

    setDictionaryInfotypeLoading,
    setDictionaryInfotypeSuccess,
    setDictionaryInfotypeFailure,

    setDictionaryDomainGroupLoading,
    setDictionaryDomainGroupSuccess,
    setDictionaryDomainGroupFailure,

    setDictionaryWordsLoading,
    setDictionaryWordsSuccess,
    setDictionaryWordsFailure,

    setMemoInfo,
    setViewport,
    setIdentifierChanged,
    updateEntityPhysicalNames,

    setModelConfig,
    updateModelConfigTab,
    updateModelConfigSection,
    removeModelConfig,

    setToolbarLists, 
    addFavorite, 
    removeFavorite,
    setLiveParticipation,
    setLiveModel,
    setLiveMdlrmdlId,
    setFavoriteList

} = commonSlice.actions;

export default commonSlice.reducer;
