import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    name:       '', // loginid, 신규
    userName:   '', // 한글명,닉네임
    email:      '', // email
    userId:     '', // userId 속성 추가, qcore의 usrId 
    authority:  [], // 모델러 권한...  
    avatar:     '', // 향후 사용자 이미지
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload, // setUser 호출로 user 정보 설정
        userLoggedOut: ()    => initialState,  // userLoggedOut 호출로 user 정보 초기화
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
