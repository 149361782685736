import React from 'react'
import { FormContextProvider, FormContextConsumer } from '../context'
import { useConfig } from '../../ConfigProvider'
import { SIZES, LAYOUT } from '../../utils/constant'
import classNames from 'classnames'

/**
 * @typedef {Object} FormContainerProps
 * @property {React.ReactNode} children - The content to be rendered inside the container
 * @property {number | string} [labelWidth=100] - Width of the label
 * @property {('horizontal' | 'vertical' | 'inline')} [layout='vertical'] - Layout of the form
 * @property {('lg' | 'sm' | 'md')} [size] - Size of the form elements
 * @property {string} [className] - Additional CSS classes
 */

/**
 * @param {FormContainerProps} props
 */
const FormContainer = ({
    children,
    labelWidth = 100,
    layout = LAYOUT.VERTICAL,
    size,
    className,
}) => {
    const { controlSize } = useConfig()

    const contextValue = {
        labelWidth,
        layout,
        size: size || controlSize,
    }

    return (
        <FormContextProvider value={contextValue}>
            <FormContextConsumer>
                {(context) => (
                    <div
                        className={classNames(
                            'form-container',
                            context.layout,
                            className
                        )}
                    >
                        {children}
                    </div>
                )}
            </FormContextConsumer>
        </FormContextProvider>
    )
}

export default FormContainer