import React from 'react'
import { Spinner } from 'components/ui'
import classNames from 'classnames'

/**
 * @typedef {Object} LoadingProps
 * @property {boolean} [loading=false] - Whether the loading state is active
 * @property {React.ReactNode} [children] - Content to render when not loading
 * @property {string} [spinnerClass] - Additional classes for the spinner
 * @property {string} [className] - Additional classes for the container
 * @property {React.ElementType} [asElement='div'] - The element type to render as
 * @property {React.ReactNode} [customLoader] - Custom loader to use instead of the default spinner
 * @property {'default' | 'cover'} [type='default'] - The type of loading display
 */

/**
 * @param {LoadingProps} props
 */
const DefaultLoading = ({
    loading,
    children,
    spinnerClass,
    className,
    asElement: Component = 'div',
    customLoader,
}) => {
    return loading ? (
        <Component
            className={classNames(
                !customLoader && 'flex items-center justify-center h-full',
                className
            )}
        >
            {customLoader ? (
                <>{customLoader}</>
            ) : (
                <Spinner className={spinnerClass} size={40} />
            )}
        </Component>
    ) : (
        <>{children}</>
    )
}

/**
 * @param {LoadingProps} props
 */
const CoveredLoading = ({
    loading,
    children,
    spinnerClass,
    className,
    asElement: Component = 'div',
    customLoader,
}) => {
    return (
        <Component className={classNames(loading ? 'relative' : '', className)}>
            {children}
            {loading && (
                <div className="w-full h-full bg-white dark:bg-gray-800 dark:bg-opacity-60 bg-opacity-50 absolute inset-0" />
            )}
            {loading && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                    {customLoader ? (
                        <>{customLoader}</>
                    ) : (
                        <Spinner className={spinnerClass} size={40} />
                    )}
                </div>
            )}
        </Component>
    )
}

/**
 * @param {LoadingProps} props
 */
const Loading = ({
    type = 'default',
    loading = false,
    asElement = 'div',
    ...rest
}) => {
    const LoadingComponent = type === 'cover' ? CoveredLoading : DefaultLoading
    return <LoadingComponent loading={loading} asElement={asElement} {...rest} />
}

export default Loading