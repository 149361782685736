import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState, clearUser  } from 'store/auth/userSlice'
import { resetState } from 'store/base/commonSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

import { useState } from 'react'
import { googleLogout, useGoogleLogin } from '@react-oauth/google'


function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const [authState, setAuthState] = useState(null)

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signInWithGoogle = async (credential) => {
        try {
            const response = await fetch('/api/google-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ credential }),
            })
            const data = await response.json()
            if (data.success) {
                const { token, user } = data
                dispatch(onSignInSuccess(token))
                dispatch(setUser(user))
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return user
            } else {
                throw new Error('Google sign-in failed')
            }
        } catch (error) {
            console.error('Google sign-in failed', error)
            throw error
        }
    }

    const signInWithNaver = async (naverUser) => {
        try {
            // 네이버 사용자 정보를 가져오는 API 호출 및 사용자 정보 설정
            console.log("signInWithNaver naverUser", naverUser)
            dispatch(setUser({ ...naverUser }))
            //navigate('/dashboard')
            navigate('/')
        } catch (error) {
            console.error('Naver sign-in failed', error)
            return { status: 'failed', message: 'Naver sign-in failed' }
        }
    }    

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    /*
    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }
    */
    const signOut = async () => {

        await apiSignOut()
        handleSignOut()

        googleLogout()
        dispatch(setUser(initialState))
        // Redux 상태 초기화
        dispatch(resetState());
        navigate('/sign-in')
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signInWithGoogle,
        signInWithNaver,
        signUp,
        signOut,
    }
}

export default useAuth
